import one from "@assets/01__in-the-eye__holmes__beauty.mp3";
import two from "@assets/02__i-wonder-if__self-doubt__jaell.mp3";
import three from "@assets/03__rename__pseudonyms.mp3";
import four from "@assets/04__by-a-woman__jaell-and-grandval.mp3";
import five from "@assets/05__created__clarinet-theme-extract.mp3";
import six from "@assets/06__a-dream__ruins-theme-a.mp3";
import seven from "@assets/07__a-cry__ruins-theme-b.mp3";
import eight from "@assets/08__unlistened__disintegration-a.mp3";
import nine from "@assets/09__to-be-lost__disintegration-b.mp3";
import ten from "@assets/10__disembodied__ruins-and-borderlands.mp3";
import onepng from "@assets/01-in-the-eye.png";
import twopng from "@assets/02-i-wonder-if.png";
import threepng from "@assets/03-(re)name.png";
import fourpng from "@assets/04-by-a-woman.png";
import fivepng from "@assets/05-created.png";
import sixpng from "@assets/06-a-dream.png";
import sevenpng from "@assets/07-a-cry_thumb.jpg";
import eightpng from "@assets/08-(un)listened.png";
import ninepng from "@assets/09-to-be-lost.png";
import tenpng from "@assets/10-disembodied.png";

export const tracks = [
  {
    src: one,
    name: "In the eye",
    asset: {
      src: onepng,
      pos: {
        mobile: {
          x: 16,
          y: 28,
          width: 30,
          maxWidth: 240,
        },
        tablet: {
          x: 18,
          y: 35,
          width: 30,
          maxWidth: 240,
        },
        tabletLandscape: {
          x: 12,
          y: 30,
          width: 40,
          maxWidth: 180,
        },
        desktop: {
          x: 18,
          y: 45,
          width: 40,
          maxWidth: 220,
        },
      },
    },
  },
  {
    src: two,
    name: "I wonder if", // large hor
    asset: {
      src: twopng,
      pos: {
        mobile: {
          x: 50,
          y: 51,
          width: 100,
          maxWidth: 360,
        },
        tablet: {
          x: 50,
          y: 50,
          width: 100,
          maxWidth: 420,
        },
        tabletLandscape: { x: 50, y: 60, width: 100, maxWidth: 1000 },
        desktop: {
          x: 70,
          y: 50,
          width: 80,
          maxWidth: 1800,
        },
      },
    },
  },
  {
    src: three,
    name: "(Re)name",
    asset: {
      src: threepng,
      pos: {
        mobile: {
          x: 70,
          y: 30,
          width: 30,
          maxWidth: 240,
        },
        tablet: {
          x: 58,
          y: 36,
          width: 30,
          maxWidth: 200,
        },
        tabletLandscape: { x: 70, y: 33, width: 40, maxWidth: 240 },
        desktop: {
          x: 60,
          y: 25,
          width: 40,
          maxWidth: 240,
        },
      },
    },
  },
  {
    src: four,
    name: "By a woman",
    asset: {
      src: fourpng,
      pos: {
        mobile: {
          x: 32,
          y: 90,
          width: 66,
          maxWidth: 240,
        },
        tablet: {
          x: 25,
          y: 90,
          width: 50,
          maxWidth: 450,
        },
        tabletLandscape: { x: 45, y: 88, width: 36, maxWidth: 450 },
        desktop: {
          x: 40,
          y: 83,
          width: 33,
          maxWidth: 600,
        },
      },
    },
  },
  {
    src: five,
    name: "Created",
    asset: {
      src: fivepng,
      pos: {
        mobile: {
          x: 33,
          y: 40,
          width: 30,
          maxWidth: 75,
        },
        tablet: {
          x: 50,
          y: 60,
          width: 40,
          maxWidth: 120,
        },
        tabletLandscape: { x: 30, y: 50, width: 40, maxWidth: 120 },
        desktop: {
          x: 50,
          y: 45,
          width: 40,
          maxWidth: 130,
        },
      },
    },
  },
  {
    src: six,
    name: "A dream",
    asset: {
      src: sixpng,
      pos: {
        mobile: {
          x: 50,
          y: 18,
          width: 50,
          maxWidth: 240,
        },
        tablet: {
          x: 50,
          y: 20,
          width: 60,
          maxWidth: 320,
        },
        tabletLandscape: { x: 50, y: 30, width: 42, maxWidth: 360 },
        desktop: {
          x: 35,
          y: 30,
          width: 40,
          maxWidth: 400,
        },
      },
    },
  },
  {
    src: seven,
    name: "A cry",
    asset: {
      src: sevenpng,
      pos: {
        mobile: {
          x: 70,
          y: 68,
          width: 60,
          maxWidth: 240,
        },
        tablet: {
          x: 80,
          y: 75,
          width: 40,
          maxWidth: 450,
        },
        tabletLandscape: { x: 85, y: 75, width: 30, maxWidth: 450 },
        desktop: {
          x: 88,
          y: 80,
          width: 33,
          maxWidth: 800,
        },
      },
    },
  },
  {
    src: eight,
    name: "(Un)listened",
    asset: {
      src: eightpng,
      pos: {
        x: 60,
        y: 55,
        mobile: {
          x: 85,
          y: 85,
          width: 40,
          maxWidth: 70,
        },
        tablet: {
          x: 85,
          y: 55,
          width: 40,
          maxWidth: 90,
        },
        tabletLandscape: { x: 13, y: 88, width: 40, maxWidth: 90 },
        desktop: {
          x: 66,
          y: 60,
          width: 40,
          maxWidth: 120,
        },
      },
    },
  },
  {
    src: nine,
    name: "To be lost",
    asset: {
      src: ninepng,
      pos: {
        mobile: {
          x: 18,
          y: 67,
          width: 30,
          maxWidth: 240,
        },
        tablet: {
          x: 17,
          y: 60,
          width: 35,
          maxWidth: 240,
        },
        tabletLandscape: { x: 12, y: 65, width: 35, maxWidth: 220 },
        desktop: {
          x: 10,
          y: 78,
          width: 40,
          maxWidth: 240,
        },
      },
    },
  },
  {
    src: ten,
    name: "Disembodied",
    asset: {
      src: tenpng,
      pos: {
        mobile: {
          x: 82,
          y: 40,
          width: 33,
          maxWidth: 240,
        },
        tablet: {
          x: 75,
          y: 35,
          width: 30,
          maxWidth: 240,
        },
        tabletLandscape: { x: 85, y: 30, width: 30, maxWidth: 240 },
        desktop: {
          x: 80,
          y: 28,
          width: 40,
          maxWidth: 240,
        },
      },
    },
  },
];
