import React, { useContext, useEffect } from "react";
import { Global, css } from "@emotion/react";
import { LoadContext } from "@providers/LoadProvider";
import OggLightWoff from "@fonts/ogg-light.woff";
import OggLightWoff2 from "@fonts/ogg-light.woff2";
import RobotoWoff from "@fonts/roboto-regular-webfont.woff";
import RobotoWoff2 from "@fonts/roboto-regular-webfont.woff2";

const GlobalFonts = () => {
  const { loadFonts } = useContext(LoadContext);

  // load fonts listener
  useEffect(() => {
    if (typeof loadFonts === "function") {
      loadFonts({
        families: ["ogg-light", "roboto"],
      });
    }
  }, [loadFonts]);

  return (
    <Global
      styles={css`
        @font-face {
          font-family: "ogg-light";
          src: local("ogg-light"), url(${OggLightWoff2}) format("woff2"),
            url(${OggLightWoff}) format("woff");
        }

        @font-face {
          font-family: "roboto";
          src: local("roboto"), url(${RobotoWoff2}) format("woff2"),
            url(${RobotoWoff}) format("woff");
        }
      `}
    />
  );
};

export default GlobalFonts;
