import React from "react";
import { Global, css } from "@emotion/react";
import { ACCENT, BLACK } from "../styles/colors";

const GlobalStyles = () => (
  <Global
    styles={css`
      body {
        margin: 0;
      }

      #___gatsby {
        margin: 0;
        font-size: 18px;
        font-family: "roboto", sans-serif;
        line-height: 1.4em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        color: rgba(33, 23, 3, 1);
        box-sizing: border-box;
        margin: 0;

        * {
          box-sizing: border-box;
        }
      }

      main {
        position: relative;
        overflow: hidden;
      }

      a,
      a:visited {
        transition: 0.15s all;
        color: ${ACCENT};
        text-decoration: none;
      }

      button {
        padding: 0px;
        background-color: transparent;
        border: none;
        transition: 0.15s all;
        cursor: pointer;
        font-size: 100%;

        color: ${ACCENT};

        &:hover,
        &:focus {
          outline: none;
          color: ${BLACK};
        }
      }

      img {
        max-width: 100%;
      }
    `}
  />
);

export default GlobalStyles;
