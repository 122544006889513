import React, { useEffect } from "react";
import LoadProvider from "@providers/LoadProvider";
import ResizeProvider from "@providers/ResizeProvider";
import PlayProvider from "@providers/PlayProvider";
import GlobalStyles from "@components/GlobalStyles";
import GlobalFonts from "@components/GlobalFonts";

const Layout = ({ children }) => {
  useEffect(() => {
    const scriptTag = document.createElement("script");
    scriptTag.src = "/patch.js";
    document.body.appendChild(scriptTag);
    setTimeout(() => {
      const stg = document.createElement("script");
      stg.src = "/main.js";
      document.body.appendChild(stg);
    }, 1200);
  }, []);
  return (
    <React.Fragment>
      <GlobalFonts />
      <GlobalStyles />
      <LoadProvider>
        <PlayProvider>
          <ResizeProvider>
            <main className="ruins-main">{children}</main>
          </ResizeProvider>
        </PlayProvider>
      </LoadProvider>
    </React.Fragment>
  );
};

export default Layout;
