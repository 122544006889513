import React, { createContext, useEffect, useState, useRef } from "react";
import { Howl } from "howler";
import { forEach, findIndex, debounce } from "lodash";
import { tracks } from "./tracks";

export const PlayContext = createContext();

/* const howlRefs = useRef([]);
  howlRefs.current = Array(tracks.length)
    .fill()
    .map((_, i) => howlRefs[i] || createRef()); */

const PlayProvider = ({ children }) => {
  const [current, setCurrent] = useState(null);
  const [tracksLoaded, setTracksLoaded] = useState(false);
  const currentRef = useRef(null);
  const howlsRef = useRef(null);

  useEffect(() => {
    if (current === null) {
      forEach(howlsRef.current, (howl, index) => {
        if (howl.playing()) {
          howl.pause();
        }
      });
    }
  }, [current]);

  useEffect(() => {
    currentRef.current = null;
    howlsRef.current = [];
  }, []);

  const onTrackMouseover = (index) => {
    setCurrent(tracks[index]);
    // check if anything else is playing
    forEach(howlsRef.current, (howl, index) => {
      if (howl.playing()) {
        howl.pause();
      }
    });

    howlsRef.current[index].play();
  };

  const onTrackMouseout = (index) => {
    setCurrent(null);
    howlsRef.current[index].pause();
  };

  const OTMouseoverDebounced = debounce(onTrackMouseover, 200);
  const OTMouseoutDebounced = debounce(onTrackMouseout, 200);

  const loadHowls = () => {
    let loaded = 0;
    if (howlsRef.current.length < 10) {
      forEach(tracks, (track, index) => {
        howlsRef.current.push(
          new Howl({
            src: [track.src],
            format: ["mp3"],
            html5: true,
            loop: false,
            onload: () => {
              loaded += 1;
              if (loaded === 10) {
                setTracksLoaded(true);
              }
            },
            // onplay: function () {
            //   console.log("tr play!", index);
            // },
            // onpause: function () {
            //   console.log("tr pause!", index);
            // },
            onend: function () {
              setCurrent(null);
            },
          })
        );
      });
    }
  };

  const loadTracks = () => {
    loadHowls();
  };

  const ctx = {
    current,
    tracks,
    onTrackMouseout: OTMouseoutDebounced,
    onTrackMouseover: OTMouseoverDebounced,
    loadTracks,
    tracksLoaded,
  };

  return <PlayContext.Provider value={ctx}>{children}</PlayContext.Provider>;
};

export default PlayProvider;
